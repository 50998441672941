<template>
  <div class="main">
    <div class="container">
      <h1>重設密碼<router-link to="/register">返回登入</router-link></h1>
      <div class="formBox">
        <div class="forgetPW resetPW">
          <p>請重新設定您的密碼</p>
          <div class="formRow">
            <h3>密<span class="t_spacex2"></span>碼</h3>
            <input
              id="password1"
              type="password"
              name=""
              value=""
              v-model="User.Password"
            />
          </div>
          <div class="formRow confirmRow">
            <h3>確認密碼</h3>
            <input id="password2" type="password" name="" value=""
            v-model="User.Password2"/>
          </div>
          <div class="submitBtn">
            <a href="javascript:void(0);" @click="register">送出</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      User: {
        Email: "",
        Name: "",
        Password: "",
        Password2: "",
        Mobile: "",
        OpenID: "",
      },
    };
  },
  async mounted() {
    this.setBase("all login");
  },
  methods: {
    async register() {
      if (!this.validate(".forgetPW")) {
        return;
      }
      if (this.User.Password != this.User.Password2) {
        alert("兩次密碼不一致，請重新輸入！");
        return;
      }
      try {
        this.$api.setToken(this.$route.query.t);
        var result = await this.$api.resetPassword(this.User);
        alert("重設成功，請重新登入");
        this.$router.replace("/");
      } catch (e) {
        console.error(e);
        alert("重設失敗");
      } finally {
        this.$api.setToken("");
      }
    },
  },
};
</script>